@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    // 600px // only screen means that this won't be applied on paper if user print it
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    // 900px
    @media only screen and (max-width: 56.25em) {
      @content;
    }
  }
  @if $breakpoint == tab-lend {
    // 1200px
    @media only screen and (max-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    // 1800px
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("./fonts/Inter/Inter-Thin.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-Thin.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("./fonts/Inter/Inter-ThinItalic.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-ThinItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("./fonts/Inter/Inter-ExtraLight.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-ExtraLight.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("./fonts/Inter/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-ExtraLightItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./fonts/Inter/Inter-Light.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-Light.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("./fonts/Inter/Inter-LightItalic.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-LightItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/Inter/Inter-Regular.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-Regular.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/Inter/Inter-Italic.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-Italic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/Inter/Inter-Medium.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-Medium.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/Inter/Inter-MediumItalic.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-MediumItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./fonts/Inter/Inter-SemiBold.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-SemiBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("./fonts/Inter/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./fonts/Inter/Inter-Bold.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-Bold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("./fonts/Inter/Inter-BoldItalic.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-BoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("./fonts/Inter/Inter-ExtraBold.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-ExtraBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("./fonts/Inter/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./fonts/Inter/Inter-Black.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-Black.woff?v=3.19") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("./fonts/Inter/Inter-BlackItalic.woff2?v=3.19") format("woff2"),
    url("./fonts/Inter/Inter-BlackItalic.woff?v=3.19") format("woff");
}

.ProjectCodePage {
  white-space: pre-line;
  font-family: "Inter", sans-serif;
  font-size: var(--font-size-main);

  h3,
  p,
  .paragraph,
  ul,
  ol {
    margin: 0;
    font-family: "Inter", sans-serif;
    color: var(--color-black);
    font-size: var(--font-size-main);
    line-height: 1.67;
    font-weight: var(--font-weight-semi-bold);

    @include respond(phone) {
      line-height: 1.5;
    }
  }

  b {
    font-weight: var(--font-weight-semi-bold);
  }

  &__header {
    font-family: "Inter";
    font-size: var(--font-size-main);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: var(--color-white);
    // box-shadow: 0px 3px 6px rgb(0 0 0 / 16%); TODO
    padding: 5px var(--section-hor-padding);
  }

  &__headerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    cursor: pointer;

    @include respond(tab-port) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__headerMobileOpen {
    @include respond(tab-port) {
      height: 100vh;
    }
  }

  &__headerTitle {
    font-size: var(--font-size-large);
    text-transform: uppercase;

    a {
      text-decoration: none;
      color: var(--color-black);
      font-weight: var(--font-weight-extra-bold);
    }
  }

  &__desktopArrow {
    @include respond(tab-port) {
      display: none;
    }
  }

  &__openedContent {
    background-color: var(--color-white);
    display: grid;
    grid-template-columns: 20rem auto;
    justify-content: center;
    column-gap: 10rem;
    row-gap: 2rem;
    font-weight: var(--font-weight-semi-bold);
    padding: 9rem 2rem 18rem;

    @include respond(tab-port) {
      padding: 0;
      grid-template-columns: auto;
      justify-content: flex-start;
      margin: 1rem var(--section-hor-padding);
      display: none;
    }

    ol {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    li:first-child {
      margin-top: -3px;
    }

    li:last-child {
      margin-bottom: -6px;
    }

    a {
      text-decoration: underline;
      color: var(--color-black);
      transition: all 0.3s;

      &:hover {
        color: var(--color-purple-light);
      }
    }
  }

  &__headerQrCode {
    @include respond(tab-port) {
      display: none;
    }
  }

  &__headerCode {
    grid-column: 2 / 3;
    color: var(--color-purple-light);
    font-size: var(--font-size-large);

    @include respond(tab-port) {
      grid-column: auto;
    }
  }

  &__desktopList {
    display: flex;
    list-style-type: none;

    @include respond(tab-port) {
      display: none;
    }
  }

  &__mobileList {
    display: none;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-left: 1.7rem;
    margin-bottom: 2rem;
    width: 100%;

    span {
      color: var(--color-purple-light);
    }

    @include respond(tab-port) {
      display: block;
    }
  }

  &__mobileHeaderLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 5rem 0;
    margin-left: -16px;
    gap: 2rem;

    a {
      width: 75%;
    }
  }

  &__mobileCodeCopy {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    gap: 2rem;
    text-align: center;
    align-items: center;

    strong {
      font-size: 3rem;
      color: var(--color-purple-light);
    }

    button {
      width: 75%;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    padding: 2rem;
    // max-width: 30rem;
    height: 26rem;
    max-height: 20rem;
    margin: 0 auto;
    box-sizing: border-box;

    @include respond(tab-port) {
      height: auto;
      max-height: none;
      padding: 30px;
    }

    img {
      height: 100%;

      @include respond(tab-port) {
        height: auto;
        width: 100%;
      }
    }
  }

  &__content {
    position: relative;
  }

  &__left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 30%;
    z-index: -1;
    background: linear-gradient(#ff5ba0, #c24ac9);

    @include respond(tab-port) {
      width: 100%;
    }
  }

  &__right {
    position: absolute;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 70%;
    z-index: -1;
    background: #e9bedc;
    background-blend-mode: multiply;

    @include respond(tab-port) {
      display: none;
    }
  }

  &__headerSection {
    margin-left: 30%;
    padding: 8rem 10rem;

    @include respond(tab-port) {
      margin-left: 0;
      padding: 3rem;
    }
  }

  &__h2 {
    margin: 0;
    font-family: "Inter", sans-serif;
    color: var(--color-black);
    font-size: var(--font-size-large);
    line-height: 1.2;
    font-weight: var(--font-weight-semi-bold);
    margin-bottom: 2.8rem;

    @include respond(tab-port) {
      margin-bottom: 1.5rem;
    }

    @include respond(phone) {
      line-height: 1.6;
    }
  }

  &__h2sub {
    font-size: 3rem;
    margin-bottom: 0;

    @include respond(tab-port) {
      font-size: 2rem;
    }
  }

  .ProjectCodePage__paragraph {
    margin-bottom: 48px;
  }

  &__videoSection {
    padding: 0 7rem;
    margin-bottom: 8rem;

    @include respond(tab-port) {
      padding: 0;
      margin-bottom: 3rem;
    }
  }

  &__video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__imageMediaSection {
    padding: 0 7rem;
    margin-bottom: 8rem;

    @include respond(tab-port) {
      padding: 0;
      margin-bottom: 3rem;
    }

    img {
      width: 100%;
    }
  }

  &__contentSection {
    margin-left: 30%;
    padding: 0 10rem;
    // margin-top: 8rem;
    padding-bottom: 8rem;
    // margin-bottom: 8rem;

    h2 {
      margin: 0;
      font-family: "Inter", sans-serif;
      color: var(--color-black);
      font-size: var(--font-size-large);
      line-height: 1.2;
      font-weight: var(--font-weight-semi-bold);
      margin-bottom: 2.8rem;

      @include respond(tab-port) {
        margin-bottom: 1.5rem;
      }

      @include respond(phone) {
        line-height: 1.6;
      }
    }

    @include respond(tab-port) {
      margin-left: 0;
      padding: 0;
      margin: 3rem;
      margin-bottom: 0;
      padding-bottom: 3rem;
    }
  }

  &__descriptionSection {
    margin-left: 30%;
    padding: 8rem 10rem;

    @include respond(tab-port) {
      margin-left: 0;
      padding: 3rem;
    }
  }

  &__qrSection {
    margin-left: -8rem;
    margin-top: 8rem;
    margin-bottom: 8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10rem;

    @include respond(tab-port) {
      margin-bottom: 3rem;
    }

    li {
      font-size: 3.6rem;
      line-height: 1.2;
      margin-bottom: 2.4rem;
    }

    @include respond(tab-port) {
      grid-template-columns: auto;
      margin-left: 0;
      margin-top: 2.4rem;
    }
  }

  &__qrCodeLink {
    color: var(--color-black);
    transition: all 0.3s;

    &:hover {
      color: var(--color-white);
    }
  }

  &__appPhoneImage {
    position: relative;
    height: min-content;

    img {
      width: 100%;
    }

    span {
      font-family: "Inter", sans-serif;
      font-size: 12px;
    }
  }

  &__appPhoneCode {
    position: absolute;
    top: 20.33%;
    left: 39.6%;
    background-color: var(--color-white);
    border-radius: 50%;
    width: 29%;
    height: 23.72%;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    gap: 8px;

    @include respond(tab-port) {
      font-size: 12px;
    }

    span {
      display: inline-block;
      border-right: 2px solid var(--color-black);
      padding-right: 2px;
      width: min-content;
      margin-left: 8px;
    }
  }

  &__appPhoneLine {
    margin-left: 8px;
    height: 1px;
    width: 93.2%;
    background-color: var(--color-black);

    @include respond(tab-port) {
      width: 90%;
    }
  }

  &__qrCode {
    margin-left: -36px;
    margin-bottom: 2.4rem;
  }

  &__qrSectionDesktop {
    @include respond(tab-port) {
      display: none;
    }
  }

  &__qrSectionMobile {
    display: none;
    text-align: center;

    @include respond(tab-port) {
      display: block;
    }
  }

  &__qrSectionLogo {
    display: none;
    width: 100%;

    img {
      width: 40%;
      margin-bottom: 3.6rem;
    }

    @include respond(tab-port) {
      display: flex;
      justify-content: center;
    }
  }

  &__mobileAppsSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    gap: 15px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__mobileLink {
    display: block;
    // width: 75%;
    // margin: 0 auto 4rem;
  }

  &__mobileCopyButton {
    display: block;
    width: 75%;
    margin: 0 auto 4rem;
    margin-top: 2.2rem;
  }

  &__imageSection {
    margin: 10rem 0 7rem 0;

    @include respond(tab-port) {
      margin: 3rem 0;
    }
  }

  &__lastContentSection {
    padding-bottom: 22rem;
  }
}
