@import '../../const';

.PosmoFooter {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 64px 0 116px 0;
	flex-direction: column;

	@include over-900px() {
		flex-direction: row; }

	.twitter-timeline {
		border-radius: 6px;
		border: 1px solid $color-border !important; }

	.PosmoFooter__company {
		margin: 40px 0 24px 0;

		p {
			color: $color-gray;
			display: flex;
			align-items:  center; }

		svg {
			margin-left: 6px; } }

	.PosmoFooter__links {
		margin-bottom: 32px;

		a {
			display: inline-block;
			margin-right: 16px; } } }
