@import '../../const';

.Input {
	font-size: 14px;
	padding: 12px;
	background: $color-light;
	width: 100%;
	border: none;
	border-radius: 4px;
	display: block;

	&:focus {
		outline: 0; }

	&::placeholder {
		color: rgba(32, 44, 61, 0.2); }

	&.Input--border {
		border: 1px solid $color-border; } }

.InputGroup {
	width: 100%;
	display: flex;
	justify-content: space-between;

	.Input {
		margin-right: 12px; }

	.Input:last-of-type {
		margin-right: 0; } }

.InputButton {
	.Input {
		border-radius: 32px;
		display: inline-block;
		padding: 16px 24px;

		@include over-600px {
			max-width: 320px; }

		&.Input--small {
			max-width: 280px; } }

	.InputButton__form {
		display: flex; } }
