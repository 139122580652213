
@import '../const';

.ContactHeader {
	padding: 60px 0 120px 0;
	background: $default-gradient-inverted;
	clip-path: polygon( 0 0, 100% 0, 100% 90%, 0 100% );
	text-align: center;

	@include over-900px() {
		padding: 120px 0 120px 0; }

	h1, h3 {
		color: $color-light; }
	h3 {
		opacity: 0.8; }

	.ContactHeader__call {
		color: $color-red-orange;
		background: $color-light;
		margin: 16px auto 40px auto;
		font-size: 16px;
		padding: 12px 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 120px;

		svg {
			margin-right: 12px; } } }

.ContactForm {
	padding: 60px 0 120px 0;
	max-width: 600px;
	width: 100%;
	margin: 0 auto;

	@include over-900px() {
		padding: 120px 0 120px 0; }

	label {
		display: block;
		margin-top: 24px;
		margin-bottom: 6px; }

	textarea {
		display: block;
		width: 100%;
		padding: 12px;
		border: 1px solid $color-border;

		&:focus {
			outline: 0; } }

	.ContactForm__submit {
		margin-top: 24px;
		display: flex;
		align-items: center;

		svg {
			margin-right: 12px; } } }
