@import '../../const';

.Message {
	padding: 20px;

	&.Message--success {
		color: $color-success; }

	&.Message--error {
		color: $color-error; } }
