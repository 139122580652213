 :root {
	--color-white: #fff;
	--color-black: #000;
	--color-silver: #e5e5e5;
	--color-silver-dark: #9d9d9d;
	--color-orange: #ff9765;
	--color-pink: #ff30c9;
	--color-purple: #8d62c9;
	--color-purple-light: #6c63ff;
	--color-purple-dark: #815cb5;
	--color-blue: #4685b1;
	--color-green: #008aa1;
	--color-yellow: #ffe202;

	--font-size-huge: 9.5rem;
	--font-size-large: 5rem;
	--font-size-main: 3rem;
	--font-size-medium: 2.2rem;

	--font-weight-light: 300;
	--font-weight-semi-bold: 600;
	--font-weight-extra-bold: 800;
	--font-weight-extra-black: 900;

	--section-hor-padding: clamp(3rem, 3.64%, 7rem);

	@media only screen and (max-width: 37.5em) {
		--font-size-huge: 3rem;
		--font-size-large: 2.5rem;
		--font-size-main: 2rem;
		--font-size-medium: 2rem; } }

$color-dark: #202c3d;
$color-light: #ffffff;
$color-border: #e6e7ed;
$color-gray: #9ea0b5;
$color-hover: #f5f6f8;

$color-orange: #ffb400;
$color-dark-orange: #ff753f;
$color-pink: #ff179b;
$color-success: #1ABA61;
$color-error: #F4394D;
$color-red-orange: #ff476a;

$default-gradient: linear-gradient(280deg, $color-orange, $color-pink);
$default-gradient-inverted: linear-gradient(280deg, $color-pink, $color-orange);

@mixin font-h1() {
	font-size: 42px;
	line-height: normal;
	font-weight: 400;
	margin: 40px 0; }

@mixin font-h2() {
	font-size: 32px;
	line-height: normal;
	font-weight: 400;
	margin: 24px 0; }

@mixin font-h3() {
	font-size: 18px;
	line-height: normal;
	font-weight: 400;
	margin: 8px 0; }

@mixin font-default() {
	font-size: 14px;
	line-height: 20px; }

@mixin font-large() {
	font-size: 18px;
	line-height: 26px; }

@mixin over-600px() {
	@media screen and (min-width: 600px) {
		@content; } }

@mixin over-900px() {
	@media screen and (min-width: 900px) {
		@content; } }

@mixin over-1200px() {
	@media screen and (min-width: 1200px) {
		@content; } }

@mixin over-1800px() {
	@media screen and (min-width: 1800px) {
		@content; } }

@mixin link-hover() {
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 3px;
		bottom: -8px;
		left: 0;
		border-radius: 100px;
		background-color: $color-light;
		transition: transform .3s;
		transition-timing-function: cubic-bezier(.665,.14,.785,.285);
		transform: scaleX(0);
		transform-origin: center center; }

	&:hover:before {
		transition-timing-function: cubic-bezier(.12,.845,.305,1);
		transform: scaleX(1); } }

@mixin link-active() {
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 3px;
		bottom: -8px;
		left: 0;
		border-radius: 100px;
		background-color: $color-light;
		transform: none;
		transition: none; }

	&:hover:before {
		transform: none; } }
