@import '../../const';

.Button {
	background: $color-dark;
	color: $color-light;
	padding: 20px 32px;
	border: none;
	border-radius: 32px;
	text-decoration: none;
	font-size: 18px;
	font-weight: 600;
	box-shadow: 0 4px 8px 0 rgba(20, 25, 31, 0.4);
	transition: all 0.2s ease;
	transform: translateY(0px);
	display: inline-block;
	cursor: pointer;

	&:focus {
		outline: 0; }

	&:hover {
		box-shadow: 0 8px 16px 0 rgba(20, 25, 31, 0.4);
		transform: translateY(-2px); }

	&.Button--small {
		font-size: 14px;
		padding: 16px 24px; }

	&.Button--input-group {
		margin-left: -48px; } }
