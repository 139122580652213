
@import '../../const';

.PosmoSection {
	max-width: 88%;
	margin: 0 auto;

	@include over-900px() {
		max-width: 800px; }

	@include over-1200px() {
		max-width: 1200px; } }
