
@import 'const';

html, h1, h2, h3, h4, h5, h6, p, a, span, small, blockquote, li, label, input, button, textarea {
	color: $color-dark;
	font-family: 'Muli', sans-serif;
	font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%

	@media only screen and (max-width: 75em) {
		font-size: 56.25%; } // 1rem = 9px

	@media only screen and (max-width: 56.25em) {
		// this will be also applied on phones
		font-size: 50%; } } // 1rem = 8px

p {
	margin: 8px 0; }

h1 {
	@include font-h1(); }

h2 {
	@include font-h2(); }

h3 {
	@include font-h3(); }

p, div, span, li, label, a {
	@include font-default(); }

.Posmo {
	perspective: 300px;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: scroll;

	.Posmo__content {
		position: relative;
		display: block;
		z-index: 1;
		min-height: 100vh;
		margin-top: -100vh; } }

// About us hover
.PosmoNavbar {
	.PosmoNavbar__submenu {
		position: relative;

		// .PosmoNavbar__submenuMain:hover:before
		// 	display: none

		.PosmoNavbar__itemHolder {
			display: none;
			position: absolute;
			left: -26px;

			@include over-1200px() {
				left: -5px; }

			&:before {
				content: ' ';
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-bottom: 10px solid $color-light;
				width: 0;
				height: 20px;
				display: block;
				margin: 0 auto; } }

		.PosmoNavbar__submenuItems {
			background: $color-light;
			border-radius: 4px;
			padding: 10px 0;
			width: 130px; }

		.PosmoNavbar__submenuItem {
			color: $color-dark;
			display: block;
			margin: 0;
			padding: 6px 24px;
			background: $color-light;
			transition: background 0.2s ease;

			&:hover {
				background: $color-hover; } } }

	.PosmoNavbar__submenu:hover {
		.PosmoNavbar__itemHolder {
			display: block; } } }

// Navbar
.PosmoNavbar {
	display: flex;
	padding: 18px 0;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	width: 100vw;
	z-index: 2;
	background: $color-light;

	@include over-900px() {
		background: transparent; }

	.PosmoNavbar__logo {
		margin-left: 24px;

		@include over-900px() {
			margin-left: 40px; }

		&.PosmoNavbar__logo--inverted svg #posmo-name {

			@include over-900px() {
				fill: $color-light; } } }

	.PosmoNavbar__links {
		display: none;
		margin-right: 40px;

		@include over-900px() {
			display: flex; }

		a {
			@include link-hover();
			text-decoration: none;
			margin: 0 0 0 16px;
			color: $color-light;
			cursor: pointer;
			position: relative;

			@include over-1200px() {
				margin: 0 0 0 40px; } }

		.PosmoNavbar--selected {
			@include link-active(); }

		.PosmoNavbar__links--common {
			display: flex; }

		.PosmoNavbar__links--user {
			margin-left: 0;

			@include over-1200px() {
				margin-left: 132px; } }

		.PosmoNavbar__links--signup {
			padding: 12px 24px;
			background: rgba(255, 255, 255, 0.3);
			border-radius: 36px;
			box-shadow: none;
			transition: all 0.2s ease;

			&:hover {
				box-shadow: 0 4px 8px 0 rgba(187, 180, 182, 0.4); }

			&:before {
				display: none; } }

		.PosmoNavbar__links--pill {
			display: flex;
			align-items: center;
			position: relative;
			margin: 0 0 0 16px;
			color: rgba(255, 255, 255, 0.4);
			cursor: default;

			@include over-1200px() {
				margin: 0 0 0 40px; }

			small {
				background: rgba(255,117,63, 0.2);
				border-radius: 16px;
				margin-left: 4px;
				font-size: 8px;
				padding: 0 8px;
				color: $color-dark-orange;
				font-weight: 600;
				opacity: 1;

				@include over-900px() {
					background: $color-light; } } } } }

// Mobile Navbar
.PosmoNavbar {
	.PosmoNavbar__links {
		&.PosmoNavbar__links--visible {

			display: block;
			position: absolute;
			width: 100vw;
			top: 68px;
			z-index: 20;
			background: $color-light;

			.PosmoNavbar__links--common, .PosmoNavbar__links--user {
				display: flex;
				flex-direction: column;
				align-items: center;

				a {
					display: flex;
					margin: 12px 0;
					color: $color-dark;
					font-size: 16px;
					justify-content: center;

					&:before {
						background: $color-dark; } }

				.PosmoNavbar__links--signup {
					background: $color-dark;
					color: $color-light;
					width: 80vw; } }

			.PosmoNavbar__links--pill {
				color: rgba(32, 44, 61, 0.4);
				font-size: 16px;
				margin: 12px 0; } } } }

// Animated hamburger menu

.PosmoHamburger {
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	background-color: transparent;
	border: 0;
	overflow: visible;
	outline: 0;
	margin-right: 24px;

	@include over-900px() {
		display: none; }

	&.PosmoHamburger--visible {
		.PosmoHamburger__inner,
		.PosmoHamburger__inner::before,
		.PosmoHamburger__inner::after {
			background-color: $color-gray; }

		.PosmoHamburger__inner {
			transform: rotate(45deg);
			transition-delay: 0.2s;
			transition-timing-function: cubic-bezier(.665,.14,.785,.285); }

		.PosmoHamburger__inner::before {
			top: 0;
			opacity: 0;
			transition: top 0.2s ease, opacity 0.2s 0.12s ease; }

		.PosmoHamburger__inner::after {
			bottom: 0;
			transform: rotate(-90deg);
			transition: bottom 0.2s ease, transform 0.2s 0.12s cubic-bezier(.665,.14,.785,.285); } }

	.PosmoHamburger__icon {
		width: 22px;
		height: 30px;
		display: block;
		position: relative; }

	.PosmoHamburger__inner {
		display: block;
		top: 50%;
		margin-top: -1px;
		transition-duration: 0.2s;
		transition-timing-function: cubic-bezier(.12,.845,.305,1);
		position: absolute;
		width: 22px;
		height: 2px;
		border-radius: 200px;
		background-color: $color-gray;

		&::before,
		&::after {
			width: 22px;
			height: 2px;
			border-radius: 200px;
			background-color: $color-gray;
			position: absolute;
			transition-property: transform;
			transition-duration: 0.2s;
			transition-timing-function: ease; }

		&::before,
		&::after {
			content: "";
			display: block; }

		&::before {
			top: -8px;
			transition: top 0.2s 0.12s ease, opacity 0.2s ease; }

		&::after {
			bottom: -8px;
			transition: bottom 0.2s 0.12s ease, transform 0.2s cubic-bezier(.12,.845,.305,1); } } }
