
@import '../const';

@keyframes fadeInFadeOut {
	0% {
		opacity: 1; }

	12.5% {
		opacity: 1; }

	16.6% {
		opacity: 0; }

	95.8% {
		opacity: 0; }

	100% {
		opacity: 1; } }

// Paralax

.PosmoParalaxGroup {
	transition: transform 0.5s;
	position: relative;
	height: 100vh;
	transform-style: preserve-3d;

	.PosmoParalaxGroup__effect {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0; }

	.Paralax--strips {
		background-image: url(../img/strips.svg);
		background-repeat: no-repeat;
		background-position: 100% 0;
		transform: translateZ(0);
		height: 140vh;
		background-size: 95%;

		@include over-1200px() {
			background-size: auto; } }

	.Paralax--rect {
		transform: translateZ(-300px) scale(2);
		background-image: url(../img/rect.svg);
		background-position: 100% 0;
		background-repeat: no-repeat;
		background-size: 70%;

		@include over-1200px() {
			background-size: auto; } } }


.LandingSection {
	padding: 60px 0;
	position: relative;

	@include over-900px() {
		padding: 120px 0; }

	&.LandingSection--header {
		max-width: 560px;
		padding: 120px 0 60px 0;

		@include over-900px() {
			padding: 220px 0 120px 0; } }

	&.LandingSection--all-images {
		display: none;

		@include over-900px() {
			display: block; } }

	&.LandingSection--images-swap {
		display: block;

		@include over-900px() {
			display: none; } }

	.LandingSection__links {
		margin-top: 40px;

		a {
			display: inline-block;
			margin-right: 16px; } }

	.LandingSection__subtitle {
		@include font-large();
		margin: 40px 0; }

	.LandingSection__transport {
		width: 100%; }

	&.LandingSection--with-image {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap; }

	.LandingSection__image-holder {
		position: relative;

		.LandingSection__transport {
			animation: fadeInFadeOut 24s ease;
			animation-iteration-count: infinite;

			&:nth-of-type(1) {
				animation-delay: 20s; }

			&:nth-of-type(2) {
				animation-delay: 16s; }

			&:nth-of-type(3) {
				animation-delay: 12s; }

			&:nth-of-type(4) {
				animation-delay: 8s; }

			&:nth-of-type(5) {
				animation-delay: 4s; }

			&:nth-of-type(6) {
				animation-delay: 0s; } } }

	.LandingSection__single-img--first {
		position: relative; }

	.LandingSection__single-img {
		position: absolute;
		left: 0; }

	.LandingSection__content {
		flex-basis: 0;
		flex-grow: 10; }

	.LandingSection__phones {
		flex-basis: 50%;
		height: auto;
		max-width: 80%;

		@include over-900px() {
			max-width: 50%; } }

	&.LandingSection--third {

		> div {
			order: 1;

			@include over-900px() {
				order: 0; } }

		h2 {
			margin-bottom: 0px; }

		h3 {
			margin-bottom: 40px; }

		.Posmo__coming-soon {
			margin-left: 8px;
			background: $default-gradient;
			background-clip: text;
			-webkit-text-fill-color: transparent;
			font-weight: 600; }

		.NotifyForm {
			margin-top: 24px; } } }


.AngledGradient {
	background-image: linear-gradient(to bottom, #ffffff, #f1f1f4);
	clip-path: polygon( 0 0, 100% 0, 100% 100%, 0 90% );

	&.AngledGradient--inverted {
		clip-path: polygon( 0 0, 100% 0, 100% 90%, 0 100% ); }

	.LandingSection {
		padding: 60px 0 160px 0;

		@include over-900px() {
			padding: 120px 0 240px 0; } } }
